import PropTypes from 'prop-types'

export const ChatScreenSkeleton = ({ stage = 1 }) => {
  return (
    <div
      style={{
        fontFamily: 'Arial, sans-serif',
        backgroundColor: '#f5f5f5',
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        margin: 0,
        opacity: 0.6,
      }}
    >
      <div
        style={{
          width: '100%',
          minHeight: '60px',
          backgroundColor: '#fff',
          marginBottom: '15px',
          borderRadius: '5px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            width: '120px',
            height: '30px',
            backgroundColor: '#efefef',
            marginLeft: '15px',
            borderRadius: '5px',
          }}
        />
        <div
          style={{
            width: '120px',
            height: '30px',
            backgroundColor: '#efefef',
            marginLeft: '15px',
            borderRadius: '5px',
          }}
        />
        <div
          style={{
            width: '120px',
            height: '30px',
            backgroundColor: '#e0e0e0',
            marginLeft: '15px',
            borderRadius: '5px',
          }}
        />
        <div
          style={{
            width: '120px',
            height: '30px',
            backgroundColor: '#efefef',
            marginLeft: '15px',
            borderRadius: '5px',
          }}
        />
        <div
          style={{
            width: '120px',
            height: '30px',
            backgroundColor: '#efefef',
            marginLeft: '15px',
            borderRadius: '5px',
          }}
        />
      </div>
      <div style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
        <div
          style={{
            flex: 1,
            flexGrow: 1,
            backgroundColor: '#fff',
            padding: '20px',
          }}
        >
          <div
            style={{
              width: '100%',
              height: '40px',
              backgroundColor: '#e0dfdf',
              marginBottom: '15px',
              borderRadius: '5px',
            }}
          />
          <div
            style={{
              width: '100%',
              height: '40px',
              backgroundColor: '#efefef',
              marginBottom: '15px',
              borderRadius: '5px',
            }}
          />
          <div
            style={{
              width: '100%',
              height: '40px',
              backgroundColor: '#efefef',
              marginBottom: '15px',
              borderRadius: '5px',
            }}
          />
          <div
            style={{
              width: '100%',
              height: '40px',
              backgroundColor: '#efefef',
              marginBottom: '15px',
              borderRadius: '5px',
            }}
          />
          <div
            style={{
              width: '100%',
              height: '40px',
              backgroundColor: '#efefef',
              marginBottom: '15px',
              borderRadius: '5px',
            }}
          />
          <div
            style={{
              width: '100%',
              height: '40px',
              backgroundColor: '#efefef',
              marginBottom: '15px',
              borderRadius: '5px',
            }}
          />
          <div
            style={{
              width: '100%',
              height: '40px',
              backgroundColor: '#efefef',
              marginBottom: '15px',
              borderRadius: '5px',
            }}
          />
          <div
            style={{
              width: '100%',
              height: '40px',
              backgroundColor: '#efefef',
              marginBottom: '15px',
              borderRadius: '5px',
            }}
          />
          <div
            style={{
              width: '100%',
              height: '40px',
              backgroundColor: '#efefef',
              marginBottom: '15px',
              borderRadius: '5px',
            }}
          />
          <div
            style={{
              width: '100%',
              height: '40px',
              backgroundColor: '#efefef',
              marginBottom: '15px',
              borderRadius: '5px',
            }}
          />
        </div>
        <div
          style={{
            flex: 1,
            flexGrow: 2,
            display: 'flex',
            flexDirection: 'column',
            padding: '20px',
          }}
        >
          <div style={{ flex: 1, overflowY: 'auto', padding: '10px 0' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column-reverse',
                gap: '10px',
              }}
            >
              {stage >= 3 && (
                <div
                  style={{
                    alignSelf: 'flex-start',
                    maxWidth: '70%',
                    backgroundColor: '#ffffff',
                    height: '40px',
                    width: '50%',
                    borderRadius: '10px',
                  }}
                />
              )}
              {stage >= 2 && (
                <div
                  style={{
                    alignSelf: 'flex-end',
                    maxWidth: '70%',
                    backgroundColor: '#e0dfdf',
                    height: '80px',
                    width: '60%',
                    borderRadius: '10px',
                  }}
                />
              )}
              {stage >= 1 && (
                <div
                  style={{
                    alignSelf: 'flex-start',
                    maxWidth: '70%',
                    backgroundColor: '#ffffff',
                    height: '120px',
                    width: '50%',
                    borderRadius: '10px',
                  }}
                />
              )}
              <div
                style={{
                  alignSelf: 'flex-end',
                  maxWidth: '70%',
                  backgroundColor: '#e0dfdf',
                  height: '80px',
                  width: '60%',
                  borderRadius: '10px',
                }}
              />
              <div
                style={{
                  alignSelf: 'flex-start',
                  maxWidth: '70%',
                  backgroundColor: '#ffffff',
                  height: '80px',
                  width: '50%',
                  borderRadius: '10px',
                }}
              />
              <div
                style={{
                  alignSelf: 'flex-end',
                  maxWidth: '70%',
                  backgroundColor: '#e0dfdf',
                  height: '80px',
                  width: '65%',
                  borderRadius: '10px',
                }}
              />
              <div
                style={{
                  alignSelf: 'flex-start',
                  maxWidth: '70%',
                  backgroundColor: '#ffffff',
                  height: '80px',
                  width: '40%',
                  borderRadius: '10px',
                }}
              />
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              gap: '10px',
              padding: '10px',
              backgroundColor: '#fff',
              borderRadius: '5px',
            }}
          >
            <div
              style={{
                flex: 1,
                height: '40px',
                backgroundColor: '#efefef',
                borderRadius: '20px',
              }}
            />
            <div
              style={{
                width: '40px',
                height: '40px',
                backgroundColor: '#e0dfdf',
                borderRadius: '50%',
              }}
            />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexGrow: 1,
            backgroundColor: '#fff',
            padding: '20px',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
              width: '40px',
            }}
          >
            <div
              style={{
                width: '100%',
                height: '40px',
                backgroundColor: '#dddddd',
                marginBottom: '15px',
                borderRadius: '5px',
              }}
            />
            <div
              style={{
                width: '100%',
                height: '40px',
                backgroundColor: '#efefef',
                marginBottom: '15px',
                borderRadius: '5px',
              }}
            />
            <div
              style={{
                width: '100%',
                height: '40px',
                backgroundColor: '#efefef',
                marginBottom: '15px',
                borderRadius: '5px',
              }}
            />
            <div
              style={{
                width: '100%',
                height: '40px',
                backgroundColor: '#efefef',
                marginBottom: '15px',
                borderRadius: '5px',
              }}
            />
            <div
              style={{
                width: '100%',
                height: '40px',
                backgroundColor: '#efefef',
                marginBottom: '15px',
                borderRadius: '5px',
              }}
            />
            <div
              style={{
                width: '100%',
                height: '40px',
                backgroundColor: '#efefef',
                marginBottom: '15px',
                borderRadius: '5px',
              }}
            />
            <div
              style={{
                width: '100%',
                height: '40px',
                backgroundColor: '#efefef',
                marginBottom: '15px',
                borderRadius: '5px',
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChatScreenSkeleton

ChatScreenSkeleton.propTypes = {
  stage: PropTypes.oneOf([1, 2, 3]),
}
